import React, { forwardRef } from 'react';
import { TextVariant } from '@naf/text';
import { MemberLabel } from '@naf/label';
import { CloudinaryImageType } from '../../../../../types/blockTypes';
import { useResponsiveImage } from '../../../hooks/useResponsiveImage';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import * as S from './Styles';

type Props = {
  article: {
    id: string;
    name: string;
    ingress: string;
    slug: string;
    isActive: boolean;
    image: CloudinaryImageType;
    step: number;
    paywallDetails?: {
      isAccessibleForUser?: boolean;
      labelText?: string;
    };
  };
  size?: 'small' | 'large';
};

const ArticleSeriesItem = forwardRef(({ article, size = 'small' }: Props, ref) => {
  const cldImage = useResponsiveImage({
    imageId: article.image?.publicId,
    quality: 'auto:low',
  });
  const link = useDocumentUrlWithNode(article);

  return (
    <S.Wrapper $isActive={article.isActive} ref={ref} to={link}>
      <S.ImgAndLabel>
        {article.isActive && <S.StyledLabel text="Du leser nå" variant="spruce" size="small" />}
        <S.StyledImg src={cldImage?.toURL() || ''} alt={article.image?.alt || article.image?.altText} $size={size} />
      </S.ImgAndLabel>
      <S.TextWrapper>
        <S.StyledText variant={TextVariant.ArticleTextHeader}>{`Del ${article.step}: ${article.name}`}</S.StyledText>
        {size === 'large' && <S.Ingress>{article.ingress}</S.Ingress>}
        {article.paywallDetails && (
          <MemberLabel
            labelText={article?.paywallDetails?.labelText || 'For medlemmer'}
            isEligible={article.paywallDetails?.isAccessibleForUser}
          />
        )}
      </S.TextWrapper>
    </S.Wrapper>
  );
});

export default ArticleSeriesItem;
