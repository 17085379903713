import React, { useRef } from 'react';
import { Star } from '@styled-icons/material-outlined/Star';
import { Text, TextVariant } from '@naf/text';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { useLocation } from 'react-router';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import useSelector from '../../redux/typedHooks';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import BlockContent from '../../components/block-content/BlockContent';
import { DocumentTypeLabel } from '../../../../types/testType';
import RelatedArticleList from '../../components/related-articles/RelatedArticleList';
import RelatedLinksList from '../../components/related-links/RelatedLinksList';
import FeedbackSurvey from '../../components/feedbacksurvey/FeedbackSurvey';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import SchemaArticle from '../../components/structured-data/SchemaArticle';
import { LoaderContent } from '../LoaderPage';
import { ArticleType } from '../../../../types/articleType';
import TableOfContents from '../../components/TableOfContents/TableOfContents';
import { ArticleHeader } from '../../components/ArticleHeader/ArticleHeader';
import ArticleSeriesHeader from '../../components/articleSeries/ArticleSeriesHeader';
import ArticleSeriesFooter from '../../components/articleSeries/ArticleSeriesFooter';
import { cloudinaryImage } from '../../utils/imageUrl';
import TravelMap from '../../components/google-maps/TravelMap';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { MetaData } from '../../components/metaData/MetaData';
import ArticleTags from '../ArticleComponents/ArticleTags/ArticleTags';
import * as S from './Styles';

interface Props {
  article: ArticleType;
}

export const path = ':mainCategorySlug/:subCategorySlug/:articleSlug';

const Article = ({ article }: Props) => {
  const appInsights = useAppInsightsContext();
  const windowPath = useWindowLocation();
  const location = useLocation();
  useTrackMetric(appInsights, 'Artikkel');
  const articleRef = useRef<HTMLDivElement | null>(null);

  const {
    name,
    runningHead,
    ingress,
    body,
    id,
    image,
    relatedContent,
    video,
    meta,
    seoConfig,
    tags,
    articleScore,
    relatedLinks,
    parent,
    category,
    slug,
    metaDisplay,
    type,
    userAccessibility,
    generateTableOfContents,
    displayFeatureHeader,
    articleSeries,
    openGraphImage,
    routePoints,
    travelMode,
    youtubeVideo,
  } = article;

  const application = useSelector((state) => state.application);
  const ogImage = openGraphImage || image;
  const cldOpenGraphImage = ogImage && cloudinaryImage(ogImage.publicId, application);
  const { mappedCategories } = useSelector((state) => state.sitestructure);

  const mappedCategory = category?.slug ? mappedCategories[category?.slug]?.data : null;
  // If article has no category, check for parent article
  const mappedParentCategory =
    !mappedCategory && parent && parent.category?.slug ? mappedCategories[parent.category.slug].data : null;
  const isUpdatingArticle = useSelector((state) => state.documents.mappedData[slug]?.meta.isUpdating);
  const articleUrl = location && location.pathname ? location.pathname : `${slug}`;
  const seoDescription = seoConfig?.introduction ? seoConfig.introduction : ingress;
  const seoTitle = seoConfig?.title ? seoConfig.title : name;
  const pageCategory = mappedCategory
    ? `${mappedCategory?.prettyUrl}/${name}`
    : `${mappedParentCategory?.prettyUrl}/${parent?.slug}/${name}`;

  return (
    <LayoutWithMainContent
      title={name}
      description={ingress}
      url={windowPath}
      imgUrl={cldOpenGraphImage}
      gtmArgs={{
        page_type: 'ArticlePage',
        pageCategory,
        contentId: id,
        author: meta?.editor?.name,
        headline: name,
        publishDate: meta?.publishAt,
        daysSincePublish: differenceInBusinessDays(new Date(), new Date(meta?.publishAt)),
        isPaywall: userAccessibility.viewType !== 'Free',
        viewType: userAccessibility.viewType,
      }}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoDescription={seoDescription}
      seoTitle={seoTitle}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Article,
        imagePublicId: image?.publicId,
        author: meta?.editor?.name,
        tags,
      }}
      backToTopButton
    >
      <S.Wrapper>
        <SchemaArticle
          title={name}
          description={ingress}
          imgUrl={cldOpenGraphImage}
          datePublished={meta?.publishAt}
          dateModified={meta?.updatedAt}
          url={articleUrl}
          id={slug}
          isAccessibleForFree={userAccessibility.viewType === 'Free'}
          author={meta?.editor?.name}
        />
        {!mappedCategory?.isHiddenCategory && !displayFeatureHeader && (
          <S.BreadCrumbsGrid>
            <S.BreadCrumbGridCol>
              <BreadCrumb />
            </S.BreadCrumbGridCol>
          </S.BreadCrumbsGrid>
        )}
        {type === DocumentTypeLabel.nafnoNewsArticle ? (
          <ArticleHeader
            name={name}
            image={image || undefined}
            ingress={ingress}
            runningHead={runningHead}
            displayFeatureHeader={displayFeatureHeader || false}
            video={video || undefined}
            youtubeVideo={youtubeVideo}
            userAccessibility={userAccessibility}
            meta={meta}
            metaDisplay={metaDisplay}
          />
        ) : (
          <ArticleHeader
            name={name}
            image={image || undefined}
            ingress={ingress}
            runningHead={runningHead}
            displayFeatureHeader={displayFeatureHeader || false}
            video={video || undefined}
            youtubeVideo={youtubeVideo}
            userAccessibility={userAccessibility}
          />
        )}

        {articleSeries && (
          <S.StyledArticleContentGrid>
            <ArticleSeriesHeader articleSeries={articleSeries} />
          </S.StyledArticleContentGrid>
        )}
        {routePoints && <TravelMap routePoints={routePoints} travelMode={travelMode} />}
        {generateTableOfContents && body && (
          <S.StyledArticleContentGrid>
            <S.ContentCol>
              <TableOfContents tagType="h2" parentElement={articleRef} />
            </S.ContentCol>
          </S.StyledArticleContentGrid>
        )}
        {relatedLinks?.links?.length > 0 && (
          <S.StyledArticleContentGrid>
            <S.RelatedGridCol>
              <Text tag="h3" variant={TextVariant.Header3}>
                {relatedLinks.header ? relatedLinks.header : 'Les også'}
              </Text>
              <RelatedLinksList relatedLinks={relatedLinks.links} />
            </S.RelatedGridCol>
          </S.StyledArticleContentGrid>
        )}
        {!!articleScore?.selectedScore && (
          <S.StyledArticleContentGrid>
            <S.ContentCol>
              <S.ScoreWrapper>
                <Text tag="h3" variant={TextVariant.Header3}>
                  Testresultat
                </Text>
                <S.ScoreIconWrapper>
                  <Star size={24} />
                  <span>
                    <b>{articleScore.selectedScore} poeng</b> {!!articleScore.maxScore && `av ${articleScore.maxScore}`}
                  </span>
                </S.ScoreIconWrapper>
              </S.ScoreWrapper>
            </S.ContentCol>
          </S.StyledArticleContentGrid>
        )}
        {isUpdatingArticle && !body ? (
          <S.StyledArticleContentGrid>
            <S.ContentCol>
              <LoaderContent />
            </S.ContentCol>
          </S.StyledArticleContentGrid>
        ) : (
          (body && (
            <S.ArticleBlockWrapper ref={articleRef}>
              <BlockContent
                context="article"
                value={body}
                isAccessibleForUser={userAccessibility.isAccessibleForUser}
              />
            </S.ArticleBlockWrapper>
          )) ||
          null
        )}
        {articleSeries && (
          <S.StyledArticleContentGrid>
            <ArticleSeriesFooter articleSeries={articleSeries} />
          </S.StyledArticleContentGrid>
        )}
        <S.StyledArticleContentGrid>
          <S.BottomContentCol>
            {userAccessibility.isAccessibleForUser ? (
              <MetaData meta={meta} metaDisplay={metaDisplay} documentType={type} displayAsText />
            ) : null}
            {tags && (
              <ArticleTags
                tags={tags}
                mappedCategories={mappedCategories}
                category={category}
                articleUrl={articleUrl}
              />
            )}
          </S.BottomContentCol>
          {relatedContent?.length > 0 && (
            <S.RelatedGridCol>
              <S.ReadMoreHRLine />
              <Text tag="h2" variant={TextVariant.Header3} id="relatedContent">
                Les også
              </Text>
              <RelatedArticleList relatedContent={relatedContent} />
            </S.RelatedGridCol>
          )}
          {!mappedCategory?.isHiddenCategory && !metaDisplay?.hideForm && (
            <S.RelatedGridCol>
              <FeedbackSurvey contentUrl={windowPath} />
            </S.RelatedGridCol>
          )}
        </S.StyledArticleContentGrid>
      </S.Wrapper>
    </LayoutWithMainContent>
  );
};

export default Article;
